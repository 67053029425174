var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInformations,
            expression: "showInformations",
          },
        ],
        staticClass: "wrapper",
      },
      [
        _c("div", { staticClass: "wrapper__card" }, [
          _c("div", { staticClass: "wrapper__card__header" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "right d-flex justify-end" },
              [
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "close-on-content-click": false,
                      "close-on-click": false,
                      "nudge-width": 200,
                      "offset-y": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "z-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      icon: "$filter_alt",
                                      text: "Filtros",
                                      primary: "",
                                      color: "primary",
                                    },
                                  },
                                  "z-btn",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.menu,
                      callback: function ($$v) {
                        _vm.menu = $$v
                      },
                      expression: "menu",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      { staticClass: "pa-5", attrs: { width: "500" } },
                      [
                        _c("div", { staticClass: "mb-6" }, [
                          _c("small", [
                            _c("strong", [
                              _vm._v("Escolha abaixo os filtros desejados:"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "v-form",
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("z-autocomplete", {
                                      attrs: {
                                        label: "Motivo do erro",
                                        type: "text",
                                        hideDetails: true,
                                        items: this.errorDetailsList.map(
                                          (d) => d.reason
                                        ),
                                      },
                                      model: {
                                        value: _vm.reasonFilter,
                                        callback: function ($$v) {
                                          _vm.reasonFilter = $$v
                                        },
                                        expression: "reasonFilter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("z-autocomplete", {
                                      attrs: {
                                        label: "Tipo",
                                        itemText: "text",
                                        itemValue: "value",
                                        hideDetails: true,
                                        items: _vm.errorTypes,
                                      },
                                      model: {
                                        value: _vm.filterType,
                                        callback: function ($$v) {
                                          _vm.filterType = $$v
                                        },
                                        expression: "filterType",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("z-input", {
                                      attrs: {
                                        label: "E-mail",
                                        type: "text",
                                        hideDetails: true,
                                        items: this.errorDetailsList.map(
                                          (d) => d.email
                                        ),
                                      },
                                      model: {
                                        value: _vm.filterEmail,
                                        callback: function ($$v) {
                                          _vm.filterEmail = $$v
                                        },
                                        expression: "filterEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("z-input", {
                                      attrs: {
                                        label: "Telefone",
                                        type: "text",
                                        hideDetails: true,
                                      },
                                      model: {
                                        value: _vm.filterNumber,
                                        callback: function ($$v) {
                                          _vm.filterNumber = $$v
                                        },
                                        expression: "filterNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-center",
                                staticStyle: {
                                  "justify-content": "space-between",
                                  width: "100%",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "left" },
                                  [
                                    _vm.hasSomeFilter
                                      ? _c("z-btn", {
                                          staticStyle: { color: "white" },
                                          attrs: {
                                            text: "Resetar",
                                            primary: "",
                                            color: "#D33246",
                                          },
                                          on: { click: _vm.resetFilters },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "right d-flex align-center",
                                    staticStyle: { gap: "0.5rem" },
                                  },
                                  [
                                    _c("z-btn", {
                                      attrs: {
                                        text: "Cancelar",
                                        primary: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.menu = false
                                        },
                                      },
                                    }),
                                    _c("z-btn", {
                                      attrs: { text: "Filtrar" },
                                      on: { click: _vm.setFilter },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.errorDetailsList.length
                  ? _c("z-btn", {
                      staticClass: "ml-2",
                      attrs: {
                        icon: " $save",
                        text: "Download",
                        primary: "",
                        color: "primary",
                        "is-loading": _vm.loadingDownload,
                      },
                      on: { click: _vm.downloadDocumentTemplates },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "wrapper__card__body" },
            [
              _c(
                "v-card",
                { attrs: { flat: "", height: "100%" } },
                [
                  _c("z-pagination", {
                    attrs: {
                      "auto-pagination": false,
                      "current-page": _vm.currentPage,
                      "number-of-pages": _vm.numberOfPages,
                      "icon-previous": "mdi-chevron-left",
                      "icon-next": "mdi-chevron-right",
                      "number-of-items": _vm.errorDetailsList.length,
                      items: _vm.errorDetailsList,
                    },
                    on: {
                      "click:next": _vm.goNextPage,
                      "click:previous": _vm.backPreviousPage,
                      "change:page": _vm.pageChangegedBySelect,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "data",
                        fn: function ({ paginatedItems }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "table-wrapper" },
                              [
                                _c("z-table", {
                                  staticClass: "table tableErrors",
                                  attrs: {
                                    headers: _vm.headers,
                                    items: paginatedItems,
                                    "sort-desc": _vm.sortDesc,
                                    "icon-next": "mdi-chevron-right",
                                    "icon-previous": "mdi-chevron-left",
                                  },
                                  on: { sort: _vm.sortTable },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "no-data",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "no-data-table" },
                                              [
                                                _c("h3", [
                                                  _vm._v(
                                                    "Sem dados disponíveis."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: `item.created`,
                                        fn: function ({ item }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formateDate(item.created)
                                                ) + " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.value`,
                                        fn: function ({ item }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.type === "mail"
                                                    ? item.email
                                                    : item.number
                                                ) + " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.number`,
                                        fn: function ({ item }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.number ||
                                                    "Sem informação"
                                                ) + " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.type`,
                                        fn: function ({ item }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.messageTypes[item.type]
                                                ) + " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.reason`,
                                        fn: function ({ item }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getErrorReason(
                                                    item.reason
                                                  )
                                                ) + " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: `item.errorMessage`,
                                        fn: function ({ item }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.errorMessage ||
                                                    "Sem informação"
                                                ) + " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showInformations,
            expression: "!showInformations",
          },
        ],
        staticClass: "wrapper no-data",
      },
      [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("h3", { staticStyle: { "font-weight": "300 !important" } }, [
        _vm._v(" Registro de falhas de envios "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }